import { FADE_IN_ANIMATION_SETTINGS } from "@/lib/constants";
import { AnimatePresence, motion } from "framer-motion";
import { useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { ReactNode } from "react";
import useScroll from "@/lib/hooks/use-scroll";
import Meta from "./meta";
import { useSignInModal } from "./sign-in-modal";
import UserDropdown from "./user-dropdown";

export default function Layout({
  meta,
  children,
}: {
  meta?: {
    title?: string;
    description?: string;
    image?: string;
  };
  children: ReactNode;
}) {
  const { data: session, status } = useSession();
  const { SignInModal, setShowSignInModal } = useSignInModal();
  const scrolled = useScroll(50);

  return (
    <>
      {/* <Meta {...meta} /> */}
      {/* <SignInModal /> */}
      <div className="fixed h-screen w-full bg-gradient-to-br from-indigo-50 via-white to-cyan-100" />
      <div
        className={`fixed top-0 w-full ${
          scrolled
            ? "border-b border-gray-200 bg-white/50 backdrop-blur-xl"
            : "bg-white/0"
        } z-30 transition-all`}
      >
        <div className="mx-5 flex h-16 max-w-screen-xl items-center justify-between xl:mx-auto">
          <Link href="/" className="flex items-center font-display text-2xl">
            <Image
              src="/serviceLogo.png"
              alt="楽々イベンティング ロゴ"
              width="200"
              height="50"
              className="mr-2 rounded-sm"
            ></Image>
          </Link>
        </div>
      </div>
      <main className="flex w-full flex-col items-center justify-center py-16">
        {children}
      </main>
      <div className="absolute w-full border-t border-gray-200 bg-white py-5 text-center ">
        <div className="mx-5 flex h-10 max-w-screen-xl items-center justify-between xl:mx-auto">
          <Link href="/" className="flex items-center font-display text-2xl">
            <Image
              src="/onex.webp"
              alt="onex logo"
              width="50"
              height="50"
              className="mr-2 rounded-sm"
            ></Image>
          </Link>
        </div>
      </div>
    </>
  );
}
